import { useEffect, useState } from "react";
import * as Firebase from "./firebase";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";

function App() {
  const [videoVoicemail, setVideoVoicemail] = useState();

  useEffect(() => {
    const urlPath = window.location.pathname.split("/");

    const videoVoicemailId = urlPath[1];

    Firebase.getVoicemail(videoVoicemailId)
      .then((fetchedVideoVoicemail) => {
        if (fetchedVideoVoicemail.exists()) {
          onDataReceived(fetchedVideoVoicemail);
        } else {
          onDataReceived(null);
        }
      })
      .catch((e) => {
        console.log("An error ocurred.");
      });
  }, []);

  const onDataReceived = (fetchedVideoVoicemail) => {
    if (fetchedVideoVoicemail !== null) {
      //this is only a soft check
      if (fetchedVideoVoicemail.data().active) {
        setVideoVoicemail(fetchedVideoVoicemail.data());
      } else {
        setVideoVoicemail({ active: false });
      }
    } else {
      setVideoVoicemail({ active: false });
    }
  };

  return (
    <div className="App">
      {videoVoicemail === undefined ? null : videoVoicemail.active ? (
        <Home prospectData={videoVoicemail} />
      ) : (
        <NotFound />
      )}
    </div>
  );
}

export default App;
