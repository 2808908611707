import { useEffect, useState } from "react";
import bcLogo from "../assets/bc-logo.svg";
import NotFound from "./NotFound";
import Splash from "./Splash";

const Home = ({ prospectData }) => {
  const bcAccountId = process.env.REACT_APP_BC_ACCOUNT_ID;
  const bcPlayerId = process.env.REACT_APP_BC_PLAYER_ID;

  useEffect(() => {
    const playerScript = document.createElement("script");
    playerScript.src =
      "https://players.brightcove.net/6415496730001/49eurqEic_default/index.min.js";
    playerScript.async = true;

    document.body.appendChild(playerScript);

    playerScript.addEventListener("load", () => {
      const player = window.videojs.getAllPlayers()[0];

      player.on("error", function () {
        setError(true);
      });
      setReady(true);
    });
  }, []);

  const [ready, setReady] = useState(false);
  const [error, setError] = useState(false);

  const onBookMeetingClick = () => {
    console.log("did click book a meeting.");
  };

  const onCta1Click = () => {
    console.log("did click CTA 1.");
  };

  if (error) {
    return <NotFound />;
  } else {
    return (
      <div id="home-container" className="w-full content-center justify-center">
        {ready ? null : <Splash />}

        <div
          id="home-content"
          className={
            "flex w-full flex-col mt-10 text-center self-center justify-center " +
            (ready ? "visible" : "hidden")
          }
        >
          <img
            src={bcLogo}
            className="w-2/5 md:w-2/6 self-center mb-5"
            alt=""
          />

          <div className="flex flex-row w-full self-center justify-center mb-5">
            <h1 className="max-w-5xl text-5xl lg:text-7xl lg:max-w-7xl font-bold text-zinc-500 leading-none tracking-tighter">
              {prospectData.content.prospectName !== undefined
                ? prospectData.content.prospectName
                : ""}
            </h1>
          </div>
        </div>

        <div
          id="video"
          className={
            "m-auto block justify-self-center self-center content-center h-full w-full lg:w-4/5 mb-2 " +
            (ready ? "visible" : "hidden")
          }
        >
          <video-js
            data-account={bcAccountId}
            data-player={bcPlayerId}
            data-embed="default"
            controls=""
            data-video-id={prospectData.content.videoId}
            data-application-id=""
            class="vjs-fluid"
          ></video-js>
        </div>

        <div
          id="info-section"
          className={
            "flex flex-col w-full self-center content-center justify-center mb-10 " +
            (ready ? "visible" : "hidden")
          }
        >
          {prospectData.content.calendarURL !== "" ? (
            <a
              href={prospectData.content.calendarURL}
              rel="noopener noreferrer"
              target="_blank"
              className="w-1/2 md:w-1/4 self-center mt-2 text-lg text-center font-semibold bg-bc text-white p-3 rounded hover:bg-bc-light transition-colors duration-300"
              onClick={onBookMeetingClick}
            >
              Book a Meeting
            </a>
          ) : null}

          <div className="flex flex-col mt-10">
            <h3 className="self-center text-2xl text-neutral-600 tracking-tighter uppercase">
              {prospectData.content.infoSection.title}
            </h3>
            <span className="self-center text-xl text-neutral-700 tracking-tight">
              {prospectData.content.infoSection.content}
            </span>
          </div>

          {prospectData.content.cta1URL !== "" ? (
            <a
              href={prospectData.content.cta1URL}
              rel="noopener noreferrer"
              target="_blank"
              className="w-1/2 md:w-1/4 mt-10 self-center text-center p-3 bg-inherit hover:bg-black hover:text-neutral-50 uppercase border border-black transition-colors duration-300"
              onClick={onCta1Click}
            >
              {prospectData.content.cta1Text}
            </a>
          ) : null}
        </div>
      </div>
    );
  }
};

export default Home;
