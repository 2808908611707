import bcLogo from "../assets/bc-logo.svg";
import spinner from "../assets/spinner.svg";
import Banner from "../components/Banner";
import { CSSTransition } from "react-transition-group";
import "../transitions.css";

const Splash = () => {
  return (
    <div
      id="splash-container"
      className="flex flex-col h-screen z-20 bg-neutral-100 content-center justify-center transition-all duration-500"
    >
      <CSSTransition
        in={true}
        timeout={500}
        classNames="bc-splash"
        unmountOnExit
        appear={true}
      >
        <div className="flex flex-col w-full self-center">
          <img
            loading="eager"
            src={bcLogo}
            className="w-3/4 self-center"
            alt=""
          />
          <Banner />
          <div className="text-center mt-6">
            <div className="inline-flex items-center justify-start gap-1">
              <img
                src={spinner}
                className="mr-2 h-12 w-12 animate-spin"
                alt=""
              />
            </div>
          </div>
          <div className="flex justify-center mt-10">
            <span className="text-4xl tracking-tighter font-semibold text-bc motion-safe:animate-bounce cursor-default select-none">
              Loading your video voicemail!
            </span>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Splash;
