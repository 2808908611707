import bcLogo from "../assets/bc-logo.svg";
import Banner from "../components/Banner";

const NotFound = () => {
  return (
    <div
      id="notFound-container"
      className="flex flex-col h-screen self-center content-center justify-center bg-neutral-100"
    >
      <img
        loading="eager"
        src={bcLogo}
        className="w-3/4 self-center mt-10"
        alt=""
      />

      <div className="flex flex-col mt-10 self-center justify-center text-center">
        <h2 className="text-3xl md:text-4xl font-bold text-neutral-700">
          This Video Voicemail has expired.
        </h2>

        <a
          href="https://www.brightcove.com/en/contact-sales/"
          target="_blank"
          rel="noopener noreferrer"
          className="w-fit self-center mt-10 "
        >
          <span className="p-3 bg-inherit hover:bg-black hover:text-neutral-50 uppercase border border-black transition-colors duration-300">
            Talk to Us
          </span>
        </a>

        <Banner />
      </div>

      <div className="flex flex-col font-bc h-fit lg:h-2/6 p-5 lg:p-0 mt-10 bg-bc text-white justify-center">
        <span className="self-center justify-self-center text-center w-3/4 lg:w-1/2 mb-10 text-3xl">
          Video can have a huge impact on your business. And Brightcove can have
          a huge impact on your video.
        </span>

        <div className="flex flex-col lg:flex-row self-center justify-center space-y-7 lg:space-y-0 lg:space-x-10">
          <div className="flex flex-col content-center justify-center text-center">
            <span className="self-center text-6xl">225%</span>
            <span className="self-center font-publicSans">
              RETURN ON INVESTMENT
            </span>
          </div>
          <div className="flex flex-col content-center justify-center text-center">
            <span className="self-center text-6xl">83%</span>
            <span className="self-center font-publicSans">
              LESS TIME SPENT MANAGING VIDEO
            </span>
          </div>
          <div className="flex flex-col content-center justify-center text-center">
            <span className="self-center text-6xl">15-25%</span>
            <span className="self-center font-publicSans">
              INCREASE IN VIEWERS
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
