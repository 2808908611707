import { useEffect, useState } from "react";

const Banner = () => {
  const taglines = [
    "COMMUNICATE MORE POWERFULLY.",
    "STREAM CONTENT MORE RELIABLY.",
    "SELL MORE EFFECTIVELY.",
  ];
  const [currentTagline, setCurrentTagline] = useState(0);
  useEffect(() => {
    var intervalId = setInterval(
      () =>
        currentTagline < taglines.length - 1
          ? setCurrentTagline((prev) => prev + 1)
          : setCurrentTagline(0),
      6000
    );

    return () => {
      clearInterval(intervalId);
    };
  }, [currentTagline, taglines.length]);

  return (
    <div
      id="banner-container"
      className="flex flex-col mt-10 mx-2 self-center font-bc"
    >
      <span className="self-center text-2xl font-bold">
        {taglines[currentTagline]}
      </span>
      <span className="self-center font-publicSans">
        With the most powerful online video platform in the world.
      </span>
    </div>
  );
};

export default Banner;
